import React from 'react';

import { Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { SearchPageData } from '@ha/contentful';
import { Language, TranslateFunc } from '@ha/intl';
import { Text, Grid, Link } from '@hbf/dsl/legacy';

import { CanonicalCollections } from 'ha/constants/SearchPageCollections';

import { useIntl } from 'ha/i18n';

import { RebrandThemeProvider } from 'ha/modules/ThemeProvider/RebrandThemeProvider';
import { Markdown } from 'ha/ui.legacy/Markdown/Markdown';

import {
  citiesInfoEnglish,
  citiesInfoGerman,
} from '../utils/seoContent/citiesInfo';

import { seoContents } from './SearchSEO';

interface Props {
  canonicalCollection: string;
  city: string;
  hermesData?: SearchPageData;
}

interface FooterSeoBlock {
  question: string;
  answer: string;
  links?: Array<{
    label: string;
    url: string;
  }>;
}

const translations = (city: string, T: TranslateFunc) => ({
  [CanonicalCollections.ApartmentForRent]: T(
    'seo_search_h2_apartment_for_rent',
    city,
    city,
  ),
  [CanonicalCollections.PrivateRoomForRent]: T(
    'seo_search_h2_private_room_for_rent',
    city,
    city,
  ),
  [CanonicalCollections.SharedRoomForRent]: T(
    'seo_search_h2_shared_room_for_rent',
    city,
    city,
  ),
  [CanonicalCollections.StudioForRent]: T(
    'seo_search_h2_studio_for_rent',
    city,
    city,
  ),
  [CanonicalCollections.CheapAccommodation]: T(
    'seo_search_h2_cheap_accommodation',
    city,
    city,
  ),
  [CanonicalCollections.FurnishedApartments]: T(
    'seo_search_h2_furnished_apartments',
    city,
    city,
  ),
  [CanonicalCollections.StudentAccommodation]: T(
    'seo_search_h2_student_accommodation',
    city,
    city,
  ),
  [CanonicalCollections.LongTermRentals]: T(
    'seo_search_h2_long_term_rentals',
    city,
    city,
  ),
  [CanonicalCollections.OneBedroomApartments]: T(
    'seo_search_h2_one_bedroom_apartments',
    city,
    city,
  ),
  [CanonicalCollections.TwoBedroomApartments]: T(
    'seo_search_h2_two_bedroom_apartments',
    city,
    city,
  ),
  [CanonicalCollections.StudentRoom]: T(
    'seo_search_h2_student_room',
    city,
    city,
  ),
  '': T('seo_search_h2', city, city),
});

const useStyles = makeStyles()(theme => ({
  container: {
    h3: {
      ...theme.typography['heading/desktop/h4-semibold'],
      color: theme.palette.secondary.main,
    },
    h4: {
      ...theme.typography['body/lg-semibold'],
      color: theme.palette.secondary.main,
    },
    p: {
      ...theme.typography['body/sm-regular'],
      color: theme.palette.secondary.main,
    },
    a: {
      ...theme.typography['link/sm'],
      color: theme.palette.secondary.main,
      textUnderlineOffset: '6px',
      display: 'inline-block',
      marginBottom: theme.spacing(3),
    },
    table: {
      width: '100%',
    },
    'table tr': {},
    'table tr:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
}));

const MarkdownWrapper = ({ children }: React.PropsWithChildren) => {
  const { classes } = useStyles();

  return <div className={classes.container}>{children}</div>;
};

export const SearchCollectionSubtitleInternal: React.FC<Props> = ({
  canonicalCollection,
  city,
  hermesData,
}) => {
  const { T, lang } = useIntl();
  const footerSeoBlock = React.useCallback((context: FooterSeoBlock[]) => {
    return (
      <React.Fragment>
        {context.map(({ question, answer, links }) => (
          <React.Fragment key={question}>
            <Text variant="h6" component="h2" gutterBottom fw="bold">
              {question}
            </Text>
            <Text
              variant="body1"
              paragraph
              dangerouslySetInnerHTML={{ __html: answer }}
            />
            {!!links && (
              <Grid container>
                {links.map(link => (
                  <Grid item xs={12} md={6} key={link.label}>
                    <Link kind="underline" to={link.url}>
                      {link.label}
                    </Link>
                  </Grid>
                ))}
              </Grid>
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }, []);
  const seoContent =
    hermesData &&
    (hermesData[seoContents[canonicalCollection]] as string | undefined);

  const subtitle = React.useMemo(
    () => seoContent || translations(city, T)[canonicalCollection],
    [seoContent, city, T, canonicalCollection],
  );

  const renderSeoContent = () => (
    <RebrandThemeProvider>
      <MarkdownWrapper>
        <Markdown content={subtitle} />
      </MarkdownWrapper>
    </RebrandThemeProvider>
  );

  if (
    !seoContent &&
    lang === Language.en &&
    citiesInfoEnglish &&
    citiesInfoEnglish[canonicalCollection] &&
    !!citiesInfoEnglish[canonicalCollection][city]
  ) {
    return footerSeoBlock(citiesInfoEnglish[canonicalCollection][city]);
  }
  if (
    !seoContent &&
    lang === Language.de &&
    citiesInfoGerman &&
    citiesInfoGerman[canonicalCollection] &&
    !!citiesInfoGerman[canonicalCollection][city]
  ) {
    return footerSeoBlock(citiesInfoGerman[canonicalCollection][city]);
  }

  return seoContent
    ? renderSeoContent()
    : subtitle && <Typography variant="subtitle1">{subtitle}</Typography>;
};

const SearchCollectionSubtitle = React.memo(SearchCollectionSubtitleInternal);
export { SearchCollectionSubtitle };
