import range from 'lodash-es/range';

function getPageData(num: number, quantity: number, activeOffset: number) {
  const offset = (num - 1) * quantity;

  return {
    num,
    offset,
    active: offset === activeOffset,
  };
}

export function getPagerData(
  total: number,
  offset: number,
  quantity: number,
  width: number,
) {
  const pagesAmount = Math.ceil(total / (quantity || 1));
  const pageNum = offset / quantity + 1;

  const pagerData = {
    pagesAmount,
    pageNum,
    prevPage: pageNum > 1 ? getPageData(pageNum - 1, quantity, offset) : null,
    nextPage:
      pageNum < pagesAmount ? getPageData(pageNum + 1, quantity, offset) : null,
    visiblePages: [] as { num: number; offset: number; active: boolean }[],
    headCollapsed: false,
    tailCollapsed: false,
    firstPage: getPageData(1, quantity, offset),
    lastPage: getPageData(pagesAmount, quantity, offset),
  };

  const indexToPageData = (index: number) =>
    getPageData(index + 1, quantity, offset);

  if (pagesAmount > width) {
    const center = Math.ceil(width / 2);
    const minPos = center;
    const maxPos = pagesAmount - (width - center);
    const pos = Math.min(maxPos, Math.max(minPos, pageNum));
    const before = center - 3;
    const after = width - center - 2;
    const startIndex = pageNum > minPos ? pos - before - 1 : 0;
    const endIndex = pageNum < maxPos ? pos + after : pagesAmount;

    pagerData.headCollapsed = pos > minPos;
    pagerData.tailCollapsed = pos < maxPos;

    pagerData.visiblePages = range(startIndex, endIndex).map(indexToPageData);
  } else {
    pagerData.visiblePages = range(0, pagesAmount).map(indexToPageData);
  }

  return pagerData;
}
