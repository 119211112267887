import React from 'react';

import loadable from '@loadable/component';

import { autoRetry } from 'ha/utils/loadable';

import {
  PageCheckboxFallback,
  PageCheckboxFallbackList,
} from '../PageCheckbox/PageCheckboxFallback';

const FallbackSkeleton = () => {
  return (
    <PageCheckboxFallbackList>
      <PageCheckboxFallback />
      <PageCheckboxFallback />
      <PageCheckboxFallback />
    </PageCheckboxFallbackList>
  );
};

export const LoadableSearchFilterFacilities = loadable(
  () =>
    autoRetry(
      () =>
        import(
          /* webpackChunkName: "DrawerSearchFilters" */ './SearchFilterFacilities'
        ),
    ),
  { fallback: <FallbackSkeleton /> },
);
