import { Logger } from '@hbf/log';

import { isClient } from 'ha/utils/checkEnvironnement';

import { getFilterValues } from '../selectors/filterSelectors';

interface BasePageInfo {
  url: string;
  resultsCount: number;
  filters: ReturnType<typeof getFilterValues>;
}

interface PageSuccess extends BasePageInfo {
  city: string;
  error?: never;
}

interface PageError extends BasePageInfo {
  error: Error;
  city?: never;
}

type Props = PageSuccess | PageError;

const logSearchPageVitals = (logger: Logger, data: Props): void => {
  if (isClient()) return;

  if ('error' in data && data.error) {
    logger.error({
      message: 'Search Page Vitals',
      status: 'error',
      error: data.error.message,
      stack: data.error.stack,
      url: data.url,
    });
  } else {
    logger.info({
      message: 'Search Page Vitals',
      ...data,
      status: data.resultsCount > 0 ? 'success' : 'empty',
    });
  }
};

export { logSearchPageVitals };
