import loadable from '@loadable/component';

import { autoRetry } from 'ha/utils/loadable';

export const LoadableSearchResultsMapNotification = loadable(() =>
  autoRetry(
    () =>
      import(
        /* webpackChunkName: "SearchResultsMapNotification" */ './SearchResultsMapNotification'
      ),
  ),
);
