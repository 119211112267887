import React from 'react';

import loadable from '@loadable/component';

import { autoRetry } from 'ha/utils/loadable';

import { PageToggleButtonContainer } from '../PageToggleButton/PageToggleButtonContainer';
import { PageToggleButtonFallback } from '../PageToggleButton/PageToggleButtonFallback';

const FallbackSkeleton = () => {
  return (
    <PageToggleButtonContainer>
      <PageToggleButtonFallback />
      <PageToggleButtonFallback />
      <PageToggleButtonFallback />
    </PageToggleButtonContainer>
  );
};

export const LoadableSearchFilterGender = loadable(
  () =>
    autoRetry(
      () =>
        import(
          /* webpackChunkName: "DrawerSearchFilters" */ './SearchFilterGender'
        ),
    ),
  { fallback: <FallbackSkeleton /> },
);
