import loadable from '@loadable/component';

import { autoRetry } from 'ha/utils/loadable';

export const LoadableUnsupportedCountriesPage = loadable(() =>
  autoRetry(
    () =>
      import(
        /* webpackChunkName: "UnsupportedCountriesPage" */ './UnsupportedCountriesPage'
      ),
  ),
);
