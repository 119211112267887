import React from 'react';

import { UniversityInfo } from '@ha/api/v2/types/UniversityInfo';

import { useSearchPageLoaderData } from './loaderData';

interface SearchPageContextInterface {
  hoveredCardId?: number;
  setHoveredCardId: (hoveredCardId?: number) => void;
  isDebouncingMapChanges?: boolean;
  setIsDebouncingMapChanges: (isLoading: boolean) => void;
  universityInfo?: UniversityInfo;
}

const SearchPageContext = React.createContext<SearchPageContextInterface>({
  setHoveredCardId: () => {},
  setIsDebouncingMapChanges: () => {},
});

export const SearchPageProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { universityInfo } = useSearchPageLoaderData();
  const [hoveredCardId, setHoveredCardId] = React.useState<number>();
  const [isDebouncingMapChanges, setIsDebouncingMapChanges] =
    React.useState<boolean>(false);

  const value = React.useMemo(
    () => ({
      hoveredCardId,
      setHoveredCardId,
      isDebouncingMapChanges,
      setIsDebouncingMapChanges,
      universityInfo,
    }),
    [hoveredCardId, isDebouncingMapChanges, universityInfo],
  );

  return (
    <SearchPageContext.Provider value={value}>
      {children}
    </SearchPageContext.Provider>
  );
};

export const useSearchPageContext = () => React.useContext(SearchPageContext);
