import { useLoaderData } from 'react-router-dom';

import { SearchPageLoaderData } from './types';

const initialData: SearchPageLoaderData = {
  universityInfo: undefined,
};

export function useSearchPageLoaderData() {
  return (useLoaderData() as SearchPageLoaderData) ?? initialData;
}
